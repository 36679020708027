.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(250, 157, 18);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Anton', sans-serif;
  font-size: calc(10px + 2vmin);
  color: rgb(112, 8, 8);
  text-align: center;
}

.App-body {
  background-color: rgb(250, 157, 18);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Anton', sans-serif;
  font-size: calc(10px + 2vmin);
  color: rgb(112, 8, 8);
  text-align: center;
}

.App-link {
  color: #1e8383;
}

.App-header-subtitle {
  font-size: calc(7px * 2vmin);
  font-family: 'Anton', sans-serif;
}

.App-info {
  font-size: calc(4px * 2vmin);
  font-family: 'Anton', sans-serif;
}

.nowrap {
  white-space: nowrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
